export default {
  page_title: "Условия и планы заработка",
  page_subtitle:
    "Настройки партнерской программы, планы заработка и их условия, возможности для дополнительного дохода.",
  fd1_partners_program_settings: "Настройки партнерской программы Turbobit",
  fd2_partners_program_settings: "Настройки партнерской программы Hitfile",
  your_current_plan: "Текущий план:",
  rate_plan_from_next_day: "Вступающий в силу план заработка (начиная со следующего дня):",
  change_rate_plan_hint: "Важно: План заработка не может быть изменен чаще чем раз в неделю.",
  change_rate_plan: "Подтвердить изменения",
  turbobit_rate_plans: "Планы заработка для Turbobit",
  hitfile_rate_plans: "Планы заработка для Hitfile",
  premium_sale: "Premium Sale (Оплата за покупки)",
  rates_for_sale_of_premium_accounts: "Рейты за продажу премиум аккаунта (турбо-доступа)",
  rates_for_sale_of_premium_accounts_hitfile: "Рейты за продажу премиум аккаунта (премиум-доступа)",
  country: "Страна",
  percent_of_sale: "% с продажи",
  percent_of_rebill: "% с ребилла",
  all_countries: "Все страны",
  mixed: "Mixed (Оплата за скачивания и покупки одновременно)",
  mixed_hint:
    "План заработка, позволяющий получать оплату как за скачивания файлов, так и за продажи премиум-аккаунтов",
  rates: "Рейты",
  group: "Группа",
  for_1000_downloads: "за 1000 скачиваний",
  for_1000_downloads_hint:
    "засчитываются только загрузки файлов размером более 10 МБ, " +
    "учитываются незавершенные скачивания, уникальность учитывается по аккаунту",
  group_and_countries: "Группы и страны",
  mixed_countries_a: "Германия, Греция, США, Турция, Дания, Польша",
  mixed_countries_b: "Бельгия, Норвегия, Словакия, Чехия, Япония, Канада, Франция, Великобритания",
  mixed_countries_c:
    "Болгария, Голландия, Испания, Италия, Казахстан, Латвия, Литва, Словения, Финляндия, Эстония, Саудовская Аравия",
  mixed_countries_d:
    "Австралия, Австрия, Беларусь, Израиль, Ирландия, Катар, Кувейт, Эмираты, Португалия, " +
    "Румыния, Швейцария, Швеция, Южная Африка",
  mixed_countries_e:
    "Азербайджан, Армения, Вьетнам, Грузия, Исландия, Люксембург, Молдова, Монако, Сингапур, Узбекистан, " +
    "Хорватия, Египет, Ирак, Иран, Иордания, Ливия, Оман, Марокко, Тунис, Сирия, Алжир, Йемен, Бахрейн",
  mixed_countries_f:
    "Бразилия, Венгрия, Гонконг, Индонезия, Киргизия, Мексика, Новая Зеландия, Сербия, " + "Таиланд, Чили",
  mixed_countries_g: "Другие страны",
  rate_chart_for_russia_and_ukraine: "Рейты для России и Украины",
  rate_chart_for_russia_and_ukraine_hint_1:
    "Отчисления за скачивания из России и Украины рассчитываются в местной валюте.",
  rate_chart_for_russia_and_ukraine_hint_2:
    "Начисление заработка производится в долларах США по текущему курсу ЦБ на " + "дату, когда была совершена загрузка",
  russia: "Россия",
  ukraine: "Украина",
  all_inclusive: "All Inclusive (Оплата за скачивания)",
  all_inclusive_hint: "План с возможностью заработка на скачиваниях ваших файлов, рейты зависят от объема файлов",
  rate_for_downloading: "Рейты за 1000 скачиваний ваших файлов",
  size_country: "Страны/Размер\tфайла",
  rate_for_downloading_hint: "учитываются незавершенные скачивания, уникальность учитывается по аккаунту",
  all_inclusive_countries_a: "Сша, Германия, Великобритания, Дания, Ирландия, Швейцария",
  all_inclusive_countries_b:
    "Латвия, Эстония, Норвегия, Швеция, Израиль, Польша, Франция, Бельгия, Италия, " +
    "Азербайджан, Австралия, Объединенные Арабские Эмираты, Кувейт, Казахстан, " +
    "Беларусь, Катар, Сингапур, Турция, Канада",
  all_inclusive_countries_c:
    "Вьетнам, Литва, Финляндия, Нидерланды, Испания, Португалия, Чехия, Кыргызская Республика, " +
    "Австрия, Словакия, Армения, Болгария, Саудовская Аравия, Юар, Греция, Бахрейн, Кипр, " +
    "Новая Зеландия, Япония, Молдова, Узбекистан, Грузия, Египет, Ирак, Иран, Иордания, Ливия, Оман, Марокко, " +
    "Тунис, Сирия, Алжир, Йемен",
  all_inclusive_countries_d: "Другие страны",
  additional_earnings: "Увеличьте свой доход",
  bonus_program: "Бонусная программа",
  gold_partners: "Золотые партнеры",
  increased_payments_and_extra_bonuses: "Увеличение дохода и дополнительные бонусы постоянным и успешным партнерам",
  increased_payments_and_extra_bonuses_1: "До <b>85%</b> с продажи на тарифе PPS;",
  increased_payments_and_extra_bonuses_2: "До <b>60%</b> с продажи + $8.75 за 1000 скачиваний на тарифе Mixed;",
  increased_payments_and_extra_bonuses_3: "До <b>$25</b> за 1000 скачиваний на тарифе PPD.",
  for: "Для",
  site_owners: "Владельцев сайтов",
  for_site_owners_line_1:
    "Дополнительные <b>+{percents}% от продаж, совершенных с вашего сайта</b>, блога, форума, " +
    "страницы в социальной сети независимо от того, кто владелец файла.",
  for_site_owners_line_2:
    "Используйте свой сайт и делитесь ссылками Hitfile " + "генерируя пассивный дополнительный доход.",
  referral_system: "Реферальная система",
  referral_system_text:
    "Получайте <b> 12% </b> дохода новых приглашенных вами партнеров с момента их регистрации " +
    "по вашей реферальной ссылке " +
    "в партнерской программе в течение следующих 3 месяцев. <br />" +
    "<i>По истечении 3 месячного периода:</i><br />" +
    "<b> 6% </b> доходов приглашенного партнера в следующие 6 месяцев; <br />" +
    "<b> 3% </b> от заработка приглашенного партнера по истечении этих 6 месяцев. <br /> <br />" +
    "Кроме того, благодаря двухуровневой реферальной системе, вы получаете 1% от дохода " +
    "каждого партнера, которого вы познакомили с партнерской программой. <br />" +
    "Если у вас нет других доходов, кроме бонусного дохода от ваших рефералов, процент " +
    "отчислений для данного вида дополнительного дохода начнет уменьшаться, " +
    "пока не достигнет 0%. Проверка проводится ежемесячно.",
  price_of_premium_account_for_users: "Цена премиум доступа для пользователей",
  price_of_premium_account_for_users_subtitle:
    "Персонализируйте цены на премиум для своих файлов. Позже вы также можете установить ставки " +
    "индивидуально для каждого файла/папки",
  standard_rates: "Стандартные ставки",
  expensive_rates: "Высокие ставки",
  my_price: "Своя цена",
  make_your_own_price_rates_variation: "Выберите подходящие вам ставки (не более {value})",
  free_download_options: "Настройки бесплатного скачивания",
  free_download_options_available_only_on_pps_rate_plan:
    "Настройки бесплатного скачивания доступны только для плана PPS",
  allow_a_free_download_of_all_files: "Разрешить бесплатное скачивание всех файлов",
  allow_a_free_download_of_all_files_hint:
    "Все файлы из всех папок будут доступны пользователям для бесплатного скачивания. " +
    "Индивидуальные настройки установленные для файлов и папок будут проигнорированы.",
  disallow_a_free_download_of_all_files: "Запретить бесплатное скачивание",
  disallow_a_free_download_of_all_files_hint:
    "Все файлы из всех папок будут недоступны для " +
    "скачивания без премиум-доступа. Индивидуальные настройки файлов и папок будут проигнорированы.",
  use_individual_settings_of_files_and_folders: "Параметры по умолчанию для недавно загруженных файлов",
  use_individual_settings_of_files_and_folders_hint:
    "Доступность бесплатного скачивания зависит от того, какие индивидуальные " +
    "настройки, выбранные вручную для конкретной папки или файла. " +
    "В противном случае применяются настройки по умолчанию.",
  inbox_free_download_label: "Выберите статус по умолчанию для новых файлов, загруженных в папку «Входящие»:",
  allow_free_download: "Бесплатное скачивание доступно",
  disallow_free_download: "Бесплатное скачивание недоступно",
  the_maximum_file_size_available_for_free_downloads:
    "Установите максимальный размер файла, доступный для бесплатного скачивания",
  the_maximum_file_size_available_for_free_downloads_label:
    "Файлы, размер которых превышает этот предел, будут недоступны " + "для скачивания без премиум-доступа:",
  free_download: { unlimited: "без ограничений" }
};
