export default {
  page_title: "Conditions and Rate plans",
  page_subtitle: "Affiliate program settings, rate plans with descriptions and options for additional income.",
  fd1_partners_program_settings: "Turbobit affiliate program settings",
  fd2_partners_program_settings: "Hitfile affiliate program settings",
  your_current_plan: "Current rate plan:",
  rate_plan_from_next_day: "The rate plan in force on the next day:",
  change_rate_plan_hint: "Important: You can change the rate plan once a week.",
  change_rate_plan: "Submit changes",
  turbobit_rate_plans: "Turbobit Rate plans",
  hitfile_rate_plans: "Hitfile Rate plans",
  premium_sale: "Premium Sales (Pay Per Sale)",
  rates_for_sale_of_premium_accounts: "Rates for sale of premium account (turbo access)",
  rates_for_sale_of_premium_accounts_hitfile: "Rates for sale of premium account (premium access)",
  country: "Country",
  percent_of_sale: "% of sale",
  percent_of_rebill: "% of rebill",
  all_countries: "All countries",
  mixed: "Mixed (Pay Per Sale & Downloads)",
  mixed_hint: "A mixed rate plan for making income out of downloads of your files along with premium accounts sales",
  rates: "Rates",
  group: "Group",
  for_1000_downloads: "for 1000 downloads",
  for_1000_downloads_hint:
    "only downloads of files larger than 10MB are counted, " +
    "incomplete downloads included. Uniqueness is calculated in the context of an account",
  group_and_countries: "Groups and countries",
  mixed_countries_a: "Germany, Greece, USA, Turkey, Denmark, Poland",
  mixed_countries_b: "Belgium, Norway, Slovakia, Czechia, Japan, Canada, France, Great Britain",
  mixed_countries_c:
    "Bulgaria, Holland, Italy, Spain, Kazakhstan, Latvia, Lithuania, Slovenia, Finland, Estonia, Saudi Arabia",
  mixed_countries_d:
    "Australia, Austria, Belarus, Israel, Ireland, Qatar, Kuwait, Emirates, Portugal, " +
    "Romania, Switzerland, Sweden, Southern Africa",
  mixed_countries_e:
    "Azerbaijan, Armenia, Vietnam, Georgia, Islandia, Luxembourg, Moldova, Monaco, Singapore, Uzbekistan, " +
    "Croatia, Egypt, Iraq, Iran, Jordan, Libya, Oman, Morocco, Tunisia, Syria, Algeria, Yemen, Bahrain",
  mixed_countries_f:
    "Brazil, Hungary, Hong Kong, Indonesia, Kirghizia, Mexico, New Zealand, Serbia, " + "Thailand, Chile",
  mixed_countries_g: "Other countries",
  rate_chart_for_russia_and_ukraine: "Rates for Russia and Ukraine",
  rate_chart_for_russia_and_ukraine_hint_1:
    "Rates to downloads from Russia and Ukraine are calculated in local currency.",
  rate_chart_for_russia_and_ukraine_hint_2:
    "Earnings are added in USD according to the current USD exchange rate of the Central Bank on " +
    "the day of download was made",
  russia: "Russia",
  ukraine: "Ukraine",
  all_inclusive: "All Inclusive (Pay Per Download)",
  all_inclusive_hint: "The rate plan for making an income by downloads, based on file size",
  rate_for_downloading: "Rates for 1,000 downloads of your files",
  size_country: "Size/Country",
  rate_for_downloading_hint: "incomplete downloads included. Uniqueness is calculated in the context of an account",
  all_inclusive_countries_a: "United States, Germany, Britain, Denmark, Ireland, Switzerland",
  all_inclusive_countries_b:
    "Latvia, Estonia, Norway, Sweden, Israel, Poland, France, Belgium, Italy, " +
    "Azerbaijan, Australia, United Arab Emirates, Kuwait, Kazakhstan, Belarus, Qatar, Singapore, Turkey, Canada",
  all_inclusive_countries_c:
    "Vietnam, Lithuania, Finland, the Netherlands, Spain, Portugal, Czech Republic, Kyrgyz Republic, " +
    "Austria, Slovakia, Armenia, Bulgaria, Saudi Arabia, South Africa, Greece, Bahrain, Cyprus, " +
    "New Zealand, Japan, Moldova, Uzbekistan, Georgia, Egypt, Iraq, Iran, Jordan, Libya, Oman, Morocco, " +
    "Tunisia, Syria, Algeria, Yemen",
  all_inclusive_countries_d: "Other countries",
  additional_earnings: "Raise your income",
  bonus_program: "Bonus program",
  gold_partners: "Gold Partners",
  increased_payments_and_extra_bonuses: "Increased rates and extra bonuses to regular and effective partners",
  increased_payments_and_extra_bonuses_1: "Up to <b>85%</b> of sales for the PPS plan;",
  increased_payments_and_extra_bonuses_2: "Up to <b>60%</b> of sales + $8.75 per 1000 downloads for the Mixed plan;",
  increased_payments_and_extra_bonuses_3: "Up to <b>$25</b> per 1000 downloads for the PPD plan.",
  for: "For",
  site_owners: "Site owners",
  for_site_owners_line_1:
    "Get <b>+{percents}% of sales made by users referred from your website</b>, blog, forum, " +
    "social network page regardless of the file owner.",
  for_site_owners_line_2: "Use your website and share Hitfile links " + "for generating extra income.",
  referral_system: "Referral system",
  referral_system_text:
    "Get <b>12%</b> of new partners' earnings from the moment they have registered with your referral link " +
    "in the affiliate program during the next 3 months.<br />" +
    "<i>After three months passed:</i><br />" +
    "<b>6%</b> of the invited partner earnings earnings the next 6 month;<br />" +
    "<b>3%</b> of the invited partner earnings after those 6 month onwards. <br /><br />" +
    "Furthermore, due to the two-level referral system, you get 1% of earnings from " +
    "each partner that was introduced to the affiliate program by you. <br />" +
    "If you don't have any other earnings except bonus income made by your referrals, the percentage of " +
    "bonus referral system income starts to decrease until it reaches 0%. The check is performed monthly.",
  price_of_premium_account_for_users: "Price of Premium Accounts for users",
  price_of_premium_account_for_users_subtitle:
    "Select the price rates. Later you can also set the rates " + "individually for each file/folder",
  standard_rates: "Standard rates",
  expensive_rates: "Expensive rates",
  my_price: "My price",
  make_your_own_price_rates_variation: "Make your own price rates variation (select up to {value})",
  free_download_options: "Free download options",
  free_download_options_available_only_on_pps_rate_plan: "Free download settings available only for the PPS rate plan",
  allow_a_free_download_of_all_files: "Allow free download of all files",
  allow_a_free_download_of_all_files_hint:
    "All files from all folders will be available for free download. " +
    "Individual settings of files and folders will be ignored.",
  disallow_a_free_download_of_all_files: "Disallow free download of all files",
  disallow_a_free_download_of_all_files_hint:
    "All files from all folders will be available for " +
    "download only with premium access. Individual settings for files and folders will be ignored.",
  use_individual_settings_of_files_and_folders: "The default download settings of newly uploaded files",
  use_individual_settings_of_files_and_folders_hint:
    "The availability of a free download depends on individual " +
    "settings that were manually selected for a specific folder or file. Otherwise, default settings applied.",
  inbox_free_download_label: "Select the default settings for files recently uploaded to your 'Inbox' folder:",
  allow_free_download: "Allow free download",
  disallow_free_download: "Disallow free download",
  the_maximum_file_size_available_for_free_downloads: "The maximum file size available for free download",
  the_maximum_file_size_available_for_free_downloads_label:
    "Files larger than a certain size will not be available " + "for free download:",
  free_download: {
    unlimited: "no limits"
  },
  rate_for_russia_and_ukraine: "Rates for Russia and Ukraine",
  rate_for_russia_hint:
    "rates for download in Russia and Ukraine is calculated in local currency. " +
    "Payment is made in USD converted by Central Bank rate actual on the day when download was made "
};
